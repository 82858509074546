import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';

const modes = ['live', 'search'];

const BASE_URL = 'http://localhost:8080';

const Sections = ({ section: {parent, name, id}, ...props }) => {
    const [mode, setMode] = React.useState('live');
    const [lastUpdated, setLastUpdated] = React.useState(null);
    const [filter, setFilter] = React.useState([]);
    const [result, setResult] = React.useState([]);
    React.useEffect(() => {
        async function browse() {
            if (!id) return;
            try {
                const result = await (await fetch(`${BASE_URL}/browse/${id}/${mode}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(filter)
                
                })).json();
                setResult(result);
                if (mode == 'live')
                    setTimeout(() => setLastUpdated(result), 5000);
            } catch (e) {
                console.error(e);
            }
        }
        browse();
    }, [mode, id, filter, lastUpdated]);
  return (<>
    <div  style={{ marginLeft: '1em', marginRight: '1em', marginTop: '1em' }}>
    <Card>
        <h1 style={{textAlign: 'center'}}>
            {parent}: {name}
        </h1>
    </Card>
    </div>
    </>);
}

export default Sections;