

const BASE_URL = 'http://localhost:8080';
const Login = () => {
    const handleLogin = async () => {
      try {
        // Gets authentication url from backend server
        const url =  await (await fetch(`${BASE_URL}/auth/url`)).text();
        console.log('url: ', url)
        // Navigate to consent screen
        window.location.assign(url);
      } catch (err) {
        console.error(err);
      }
    }
    return <>
    <h3>Login to Dashboard</h3>
    <button className="btn" onClick={handleLogin} >Login</button>
    </>
}  

export default Login;