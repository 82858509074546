import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const BASE_URL = 'http://localhost:8080';

const Sections = ({ setSection }) => {
    const defaultSections = localStorage.getItem('sections') || '[]';
    const [sections, setSections] = React.useState(JSON.parse(defaultSections));
    const [hasLoaded, setHasLoaded] = React.useState(false);
  React.useEffect(() => {
    async function fetchSections() {
        if (!hasLoaded) {
            try {
                const result = await (await fetch(`${BASE_URL}/browse/sections`)).json()
                setSections(result);
                setHasLoaded(true);
                localStorage.setItem('sections', JSON.stringify(result));
            } catch (e) {
                console.error(e);
            }
        }
    }
    fetchSections();
  }, [sections]);
  return (
    <>
      {sections.map(({header, sub_sections}, index) => (
      <Accordion defaultExpanded key={`${header}-key`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${header}-content`}
          id={`${header}-header`}
        >
          {header}
        </AccordionSummary>
        <AccordionDetails>
          <List>
                    {sub_sections.map(({name, id}, index) => (
                    <ListItem key={name} disablePadding>
                        <ListItemButton onClick={() => setSection({parent: header, name, id})}>
                            <ListItemText primary={name} />
                        </ListItemButton>
                    </ListItem>
                    ))}
                </List>
        </AccordionDetails>
      </Accordion>))}
    </>
  );
}

export default Sections;