
const BASE_URL = 'http://localhost:8080';
import { useNavigate } from 'react-router-dom';
const Callback = () => {
    const called = useRef(false);
    const { checkLoginState, loggedIn } = React.useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
      (async () => {
        if (loggedIn === false) {
          try {
            if (called.current) return; // prevent rerender caused by StrictMode
            called.current = true;
            const res = await fetch(`${BASE_URL}/auth/token${window.location.search}`);
            console.log('response: ', res);
            checkLoginState();
            navigate('/');
          } catch (err) {
            console.error(err);
            navigate('/');
          }
        } else if (loggedIn === true) {
          navigate('/');
        }
      })();
    }, [checkLoginState, loggedIn, navigate])
    return <></>
  };

export default Callback;