'use strict';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import NavBar from './component/nav.js';
import Sections from './component/sections.js';
import Section from './component/section.js';
import Login from './component/login.js';
import Callback from './component/login_callback.js';
import TermsOfService from './component/tos.js';
import Privacy from './component/privacy.js';
const BASE_URL = 'http://localhost:8080';
const App = () => {
    
    const { user, loggedIn, checkLoginState } = React.useContext(AuthContext);

    const [page, setPage] = React.useState('Services');

    const defaultSection = localStorage.getItem('section');
    const [section, setSectionState] = React.useState(defaultSection ? JSON.parse(defaultSection) : {});

    const setSection = (section) => {
        setSectionState(section);
        localStorage.setItem('section', JSON.stringify(section));
    }


    return (
        <div>
        <NavBar setPage={setPage}/>
        {loggedIn ? 
        <>{page == 'Browse' ?
        <Grid container>
                <Grid item lg={1} md={2} xs={12}>
                    <Item><Sections setSection={setSection}/></Item>
                </Grid>
                <Grid item lg={11} md={10} xs={12}>
                    <Item>
                        <Section section={section} />
                    </Item>
                </Grid>
            </Grid>
        : <></>}
        {page == 'Order' ?
            <>Order</>
            : <></>}
        {page == 'About' ?
        <>About</>
        : <></>}
        </>:
        <Login />
        }
        </div>
    );
};

const AuthContext = React.createContext();

const AuthContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = React.useState(null);
  const [user, setUser] = React.useState(null);

  const checkLoginState = React.useCallback(async () => {
    try {
        const token = localStorage.getItem('token') || null;
        console.log('token: ', token);
      const { is_logged_in, name } = await (await fetch(`${BASE_URL}/auth/logged_in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: token })
            })).json();
      setLoggedIn(is_logged_in);
      name && setUser(name);
    } catch (err) {
      console.error(err);
    }
  }, []);

  React.useEffect(() => {
    checkLoginState();
  }, [checkLoginState]);

  return (
    <AuthContext.Provider value={{ loggedIn, checkLoginState, user }}>
      {children}
    </AuthContext.Provider>
  );
};


const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
    },
    {
      path: '/auth/callback', // google will redirect here
      element: <Callback />,
    },
    {
        path: '/tos',
        element: <TermsOfService />,
    },
    {
      path: '/privacy',
      element: <Privacy />,
    }
  ]);

const domContainer = document.getElementById('App');
const root = createRoot(domContainer);
root.render(<AuthContextProvider>
    <RouterProvider router={router} />
</AuthContextProvider>);